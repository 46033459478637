/**************************************
* DEFAULT MODAL STYLE
**************************************/
// .modal{
//   @include media('small'){
//     // padding-top: 155px;
//   }
// }
.modal{ 
  .btn{
    background: white;
    color: $second-color;
    border: 1px solid $second-color;
    border-radius: unset;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    &:hover{
      background-color: $second-color;
      color: $font-color-2;
    }
  }
  .close{
    position: absolute;
    right: 10px;
    top: -13px;
    background: transparent;
    border: 0;
    font-size: 45px;
  } 
}

/* Modal cálculo do frete */
.modal-calculo-frete{
  border-radius: unset;
  width: 272px;
  min-height: 200px;
  vertical-align: middle;
  left: 50%;
  position: relative;
  @include transform(translateX(-50%));
  @include media('mf-small'){
    width: 422px;
  }
  .modal-header-calculo-frete{
    padding: 3px;
    font-size: 29px;
    color: $second-color;;
    .close{     
      top: -5px;
      right: 4px;
      z-index: 2;
      font-size: 31px;
    }
  }
  .modal-body{
    display: block;
    @include media('mf-x-small'){
      padding: 15px 20px;
    }
    .modal-title{
        margin-top: -8px;
        text-transform: uppercase;
      }
      .frete{
        padding: 21px 0;
        .left{
          .title{
            text-transform: uppercase;
            font-size: 10px;
          }
          .calculo{
            display: flex;
            width: 100%;
            border: 1px solid $second-color;
            margin: 15px 0;
            height: 35px;
            input{
              width: 85%;
              padding: 10px;
            }
            button{
              width: 15%;
              border: 0;
              border-left: 1px solid $second-color;
              background-color: $primary-color;
            }
          }
        }
        .right{
          color: $font-color-3;
          .list{
            color: $font-color;
            column-count: 2;
            line-height: 1.5;
            p{
              padding-bottom: 6px;
              display: inline-block;
              b,
              strong{
                display: block;
              }
            }
          }
        }
      }
  }
}
/* Fim modal cálculo do frete */

.modal-backdrop{
 display: none;
}
.modal{
 background-color: rgba(0, 0, 0, 0.7);
}
.modal-body {
 margin-left: auto;
 margin-right: auto;
 display: table;
}
.modal-dialog {
  top: 50%;
  transform: translateY(-50%) !important;
}
#modal-aviseme .modal-dialog {
  top: unset;
  transform: unset !important;
}

.modal-content{
  border-radius: unset;
  .modal-header{
    border: none;
    padding: 8px;
    border-bottom: 1px solid #e5e5e5;
  }
}

