/**************************************
* BLOCO DE AVALIAÇÕES (from AM code)
**************************************/
@import "../base/variables";
@import "../base/mixins";

.avaliacoes{
  label{
    font-weight: normal;
    font-size: 13px;
    color: #666;
  }
  input.radio-recomenda{
    margin-right: 10px;
    display: inline-block;
  }
  span.i-recommend{
    background: url('../../images/recomendo.png');
    width: 170px;
    height: 28px;
    display: block;
    font-size: 0.95em;
    padding: 7px 10px;
    margin-bottom: 7px;
  }
  .apr-coluna-pro,
  .apr-coluna-contra{
    input{
      margin-bottom: 15px;
    }
  }
  .avaliation-title{
    display: none;
    span, strong{
      font-size: 13px;
      color: #666;
      &.product_name {
        padding-top: 10px;
        display: block;
      }
    }
  }
  .form-control{
    border: 1.5px solid $second-color;
    border-radius: unset;
    resize: none;
  }
  .pros{
    width: 25px;
    height: 25px;
    background: #00a651;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 15px;
  }
  .contras{
    width: 25px;
    height: 25px;
    background: #ed1c24;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 15px;
  }
  .txt-pros-cnt{
    display: inline-block;
  }
  .pros i,
  .contras i{
    font-size: 14px;
    color: #fff;
    text-align: center;
    padding: 0px;
    margin: 0px;
    vertical-align: middle;
    line-height: 25px;
    display: block;
  }
  .product_name strong{
    font-weight: bold;
    text-transform: uppercase;
  }
  // .faca-avaliacao{
  //   background: #0266a6;
  //   font-size: 15px;
  //   color: #fff;
  //   border: 0px;
  //   padding: 5px 10px;
  //   margin-left: 25px;
  //   font-weight: 100;
  //   letter-spacing: 1px;
  // }
  // .faca-avaliacao:hover{
  //   opacity: 0.8;
  // }
  .total-reviews{
    display: inline-block;
    margin-left: 25px;
  }
  .rating-points{
    display: inline-block;
  }
  .avaliation-block-middle{
    background: #fff;
    display: none;
  }
  .col-middle-1{
    border-right: 1px solid $primary-color;
    // padding-left: 0;

    h2{
      display: none;
    }
    textarea,input{
      margin-bottom: 17px;
    }
  }
  .avalation-middle-form label{
    font-weight: normal;
  }
  .avaliation-obs{
    font-size: 0.95em;
    color: #585858;
  }
  .avaliation-obs strong{
    font-weight: normal;
    color: #ff0000;
  }
  .col-middle-2{
    padding-right: 0;
    .middle-col-2{
      margin-left: 0;

    }
    span{
      font-size: 15px;
      color: #666;
    }
  }
  .avaliation-stars-block{
    margin-top: 7px;
    margin-bottom: 20px;
  }
  .avaliation-stars-block i{
    font-size: 1.3em;
    color: $primary-color;
  }
  .avaliation-bars{
    margin-bottom: 20px;
    margin-top: 5px;

    span{
      font-size: 1.1em;
      color: #696969;
      margin-left: 15px;
    }
    .bar{
      display: inline-block;

      &:before{
        content: "\f005";
        font-size: 20px;
        cursor: pointer;
        color: #d8d8d8;
      }
      &:hover{
        &:before{
          color: #696969;
        }
      }
      &.active,
      &.point-on{
        &:before{
          color: #ff9c00;
        }
      }
      input{
        display: none;
      }
    }
  }

  .upload-button{
    margin: 10px 0;
  }
  .recommend-block{
    margin-top: 20px;
  }
  .recommend-block label{
    padding-top: 2px;
  }
  .recommend-block .pull-left.first{
    margin-right: 80px;
  }
  .captcha-block input,
  .captcha-block img{
    width: 45%;
    margin-top: 10px;
  }
  .apr-sem-avaliacoes{
    color: #696868;
    padding: 20px 0px;
    
    @include media ('sm'){
      margin-top: unset;
    }
  }
  .form-review{
    padding: 20px 0px;
    padding-bottom: 0;
  }
  .avaliation-footer-middle{
    padding: 17px;
    border-top: 1px solid $primary-color;
    border-bottom: 1px solid $primary-color;
    margin: 0 -20px;
    margin-top: 17px;
    clear: both;
    text-align: right;

    .btn-enviar{
      font-size: 1.2em;
      padding: 9px 65px;
      background: $second-color;
      color: $primary-color;
      border: 0px;
      text-transform: uppercase;
      font-weight: bold;

      &:hover{
        opacity: 0.9;
      }
    }
    .btn-cancelar{
      font-size: 12px;
      padding: 5px 15px;
      background: #fff;
      font-weight: normal;
      border: 0px;
      text-transform: uppercase;

      &:hover{
        text-decoration: underline;
      }
    }
  }
  .avaliation-redes{
    display: block;
    float: left;
    width: 25px;
    height: 20px;
    background: url('../../images/redes.png');
    margin-right: 0px;
  }
  .facebook {
    background-position: 0px center;
  }
  .twitter {
    background-position: 71px center;
  }
  .google {
    background-position: 46px center;
  }
  .avaliation-comment{
    background: #fff;
    margin-top: 30px;
    border: 1px solid #d7d7d7;
    padding: 20px 15px;
  }
  .avaliation-comment span{
    font-size: 1.2em;
  }
  .col-comment-1 strong{
    font-size: 1.4em;
    font-weight: bold;
    color: #000;
    display: block;
    margin-bottom: 15px;
  }
  .col-comment-1-share{
    display: block;
    float: left;
    color: #585858;
    font-size: 1.1em;
    font-weight: bold;
    margin-right: 12px;
  }
  .col-comment-2 i{
    font-size: 1.2em;
    color: $primary-color;
  }
  .col-comment-2 strong{
    font-size: 1.2em;
    font-weight: bold;
    color: #000;
    margin: 0 7px;
    display: inline-block;
  }
  .col-comment-2 span{
    color: #585858;
    font-weight: normal;
    font-size: 1em;
  }
  .col-comment-2 p{
    margin-top: 15px;
    display: block;
    margin-bottom: 0;
  }
  .avaliation-fotos img{
    width: 50px;
    height: 50px;
    border: 1px solid #d7d7d7;
    display: block;
    float: left;
    margin-right: 10px;
  }
  .avaliation-fotos span{
    display: block;
    margin-bottom: 5px;
  }
  .col-comment-2{
    border-left: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    padding-bottom: 10px;
    min-height: 150px;
  }
  .col-comment-2 p.full-text{
    display: none;
  }
  .avaliation-comment-footer{
    clear: both;
    padding-top: 17px;
    margin-top: 17px;
    border-top: 1px solid #d7d7d7;
  }
  .avaliation-util strong{
    font-weight: bold;
    font-size: 1.3em;
    color: #585858;
    display: inline-block;
    margin-right: 10px;
  }
  .avaliation-util button{
    background: transparent;
    border: 0px;
    color: #364ea2;
    font-size: 1.3em;
  }
  .avaliation-denuncia button:active:focus,
  .avaliation-util button:active:focus{
    opacity: 0.5;
  }
  .avaliation-denuncia button:hover,
  .avaliation-util button:hover{
    opacity: 0.8;
  }
  .avaliation-util button i{
    display: inline-block;
    margin-right: 2px;
  }
  .avaliation-denuncia button{
    background: transparent;
    border: 0px;
    color: #d52626;
    font-size: 1.1em;
  }
  .avaliation-denuncia button i{
    display: inline-block;
    margin-right: 2px;
  }
  .btns-upload{
    overflow: hidden;
    padding-right: 10px
  }
  .place-prazo{
    margin-top: 15px;
  }
  .place-prazo .check-prazo {
    color: #7f7f7f;
    font-size: 13px;
    font-weight: 400;
  }
  .opcao-obrigatoria{
    color: #fff;
    float: left;
    font-size: 12px;
    font-weight: 700;
    margin-top: 5px;
    border-radius: 2px;
    padding: 5px 2%;
    background: #d1334a;
  }
  .place-prazo .place-termo-aceitacao {
    background-color: #dedede;
    border: 1px solid #bababa;
    float: left;
    margin-top: 10px;
  }
  .place-prazo .place-termo-aceitacao .fa {
    width: 10%;
    float: left;
    font-size: 70px;
    color: #9F9F9F;
    margin-top: 15px;
    padding:5px;
  }
  .place-prazo .place-termo-aceitacao .conteudo {
    width: 90%;
    float: left;
  }
  .place-prazo .place-termo-aceitacao h3 {
    color: #000;
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 12px;
    padding-left:5px;
  }
  .place-prazo .place-termo-aceitacao p {
    color: #666;
    font-size: 12px;
    padding: 0px 5px;
  }
  .place-prazo .place-termo-aceitacao p a {
    color: #050505;
  }
}
@include media('small'){
  .avaliacoes{
    .col-middle-1{
      border: 0;
    }
    .avaliation-footer-middle,
    .captcha{
      display: flex;
      justify-content: center;
    }
  }
}
.lista-de-avaliacoes{
  .apr-avaliacao{
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;

    .left{
      .review_list_title{
        font-size: 14px;
        font-weight: bold;
        color: $second-color;
      }
      .rating-points{
        .fa{
          color: #bababa;

          &.point-on{
            color: #ff9c00;
          }
        }
      }
      .texto-pros-contras{
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 13px;
        color: $font-color;
        font-weight: lighter;
      }
      .review_list_author{
        font-size: 13px;
        color: $second-color;
        font-weight: 300;

        strong{
          font-weight: 300;
          color: $second-color;
        }
      }
    }
    .right{
      min-width: 30%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .like-avaliation{
        // display: block;
        // color: $primary-color;
        // border-radius: 5px;
        border-radius: 5px;
        border: 0;
        background-color: $primary-color;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
        width: 190px;
        height: 35px;
        padding: 0px 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: 200ms ease;

        &:hover{
          background: darken($primary-color, 5%);
        }
        span{
          font-size: 13px;
          color: $font-color;
          text-decoration: underline;
          font-weight: 600;

          i{
            margin-right: 5px;
          }
        }
        b{
          font-size: 16px;
          font-weight: 300;
          color: $font-color;
        }
      }
    }
  }

  @include media('small'){
    .apr-avaliacao{
      flex-wrap: wrap;

      .right{
        width: 100%;
        margin-top: 15px;

        .product_review_helpfulness{
          width: 100%;

          .product_review_vote{
            width: 100%;
            // display: flex;
            align-items: center;
            justify-content: space-between;

            .like-avaliation{
              width: 100%;
              display: block;
              text-align: left;

              b{
                float: right;
              }
            }
          }
        }
      }
    }
  }
}