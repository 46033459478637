/**************************************
* DEFAULT MIXINS AND FUNCTIONS
**************************************/

@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}

@mixin clearfix {
  &:before,
  &:after{
    content: "";
    display: table;
    clear: both;
  }
}

@mixin no-select{
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
        user-select: none;
}

@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin column($num, $gap){
  -moz-column-count: $num;
  -moz-column-gap: $gap;
  -webkit-column-count: $num;
  -webkit-column-gap: $gap;
  column-count: $num;
  column-gap: $gap;
}

@mixin media($size){
  @if $size == xxs{
    @media(max-width: 450px){ @content; }
  }
  @if $size == xs{
    @media(max-width: 767px){ @content; }
  }
  @if $size == xsm{
    @media(min-width: 449px) and (max-width: 768px){ @content; }
  }  
  @if $size == sm{
    @media(min-width: 768px) and (max-width: 991px){ @content; }
  }
  @if $size == xmd{
    @media(min-width: 451px) and (max-width: 991px){ @content; }
  }  
  @if $size == md{
    @media(min-width: 992px) and (max-width: 1199px){ @content; }
  }
  @if $size == lg{
    @media(min-width: 1200px){ @content; }
  }
  @if $size == xlg{
    @media(min-width: 1350px){ @content; }
  }
  @if $size == xxlg{
    @media(min-width: 1600px){ @content; }
  }
  @if $size == small{
    @media(max-width: 991px){ @content; }
  }
  @if $size == large{
    @media(min-width: 992px){ @content; }
  }
  @if $size == custom-small{   /* tablet */
    @media(min-width: 500px){ @content; }
  }  

  /* Breakpoints mobile first */
  @if $size == mf-small{   /* tablet */
    @media(min-width: 450px){ @content; }
  }
  @if $size == mf-x-small{ /* tablet-full */
    @media(min-width: 768px){ @content; }
  }  
  @if $size == mf-large{   /* desktop */
    @media(min-width: 992px){ @content; }
  }
  @if $size == mf-x-large{ /* desktop full */
    @media(min-width: 1200px){ @content; }
  }    

  /* Breakpoints limitadores */
  @if $size == lim-sm{ /* tablet */
    @media(min-width: 451px) and (max-width: 767){ @content; }
  }   
  @if $size == lim-md{ /* tablet-full */
    @media(min-width: 768px) and (max-width: 991){ @content; }
  } 
  @if $size == lim-x-md{ /* tablet-full */
    @media(min-width: 768px) and (max-width: 1199){ @content; }
  }   
  @if $size == lim-lg{ /* desktop */
    @media(min-width: 992px) and (max-width: 1199){ @content; }
  }
  @if $size == custom-tablet{ /* tablet */
    @media(min-width: 500px) and (max-width: 991){ @content; }
  }
  @if $size == custom-01{ /* tablet */
    @media(min-width: 645px){ @content; }
  }
  @if $size == custom-02{ /* desktop */
    @media(min-width: 992px) and (max-width: 1199){ @content; }
  }  

}

/**************************************
* Sempre que usar um valor de z-index, nomei-o igual a sua classe
  .site-header {
    z-index: z('site-header');
  }
**************************************/
// @function z($name) {
//     @if index($z-indexes, $name) {
//         @return (length($z-indexes) - index($z-indexes, $name)) + 1;
//     } @else {
//         @warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';
//         @return null;
//     }
// }
// $z-indexes: (
//   ".btn-product-detail",
//   ".btn-product-detail2",
//   "main-menu"
// );

/**************************************
* Limite de linhas
  Exemplo de uso:
  p{
    font-size: 13px;
    color: black;
    @include line-limit(3, 21px);
  }
**************************************/
@mixin line-limit($lines, $height){
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  line-height: $height;
  max-height: $height*$lines;
}

/**************************************
* Estilizar placeholder
  Exemplo de uso: 
  textarea {  
    @include placeholder {
      color: $grey;
    }
  }
**************************************/
@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

/**************************************
* Pseudo Elementos
  Exemplo de uso: 
    div::after { 
      @include pseudo;
      top: -1rem;
      left: -1rem;
      width: 1rem;
      height: 1rem;
    }
**************************************/
@mixin pseudo($display: block, $pos: absolute, $content: ""){
  content: $content;
  display: $display;
  position: $pos;
}

/**************************************
* Alinha verticalmente um elemento
  Exemplo de uso: div { @include vertical-align(); } 
**************************************/
@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
/**************************************
* Container personalizado - bloca o conteúdo na resolução full hd
  Exemplo de uso: div { @include container-custom(); } 
**************************************/
@mixin container-custom {
  max-width: 1451px;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
}
/**************************************
* Força a centralização de quase todos os tipos de elementos
  Exemplo de uso: div { @include align-center(); } 
**************************************/
@mixin align-center {
  display: table;
  margin-left: auto;
  margin-right: auto;
}