@-webkit-keyframes grow{
  0%{
    width: 0px;
  }
  100%{
    width: 100%;
  }
}
@keyframes grow{
  0%{
    width: 0px;
  }
  100%{
    width: 100%;
  }
}
body {
	margin: 0;
	padding: 0;
	background: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
	color: $font-color;
  font-weight: normal;
  margin-top: 65px;
  overflow-x: hidden;

  @include media('mf-x-small'){
    margin-top:160px;
  }
  @include media('mf-large'){
    margin-top: 208px;
  }  
}
.custom-top{
  margin-top: 125px;
}

ul,nav,li,p,h1,h2,h3,h4{
  padding: 0;
  margin: 0;
}

.btn:active,
.btn:focus,
.btn:active:focus,
.btn,
button,
input,
.form-control:active,
.form-control:focus,
.form-control:active:focus,
.form-control {
	box-shadow: none;
	outline: none;
}
.header .container{
  max-width: 1351px;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.flex{
  display: flex;
}
#mm-blocker{
  z-index: 999999;
}
.underlined{
  text-decoration: none;
  position: relative;

  &:after{
    content: "";
    width: 0;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #000;
  }
  &:hover{
    text-decoration: none;

    &:after{
      animation: grow 420ms;
      animation-fill-mode: forwards;
    }
  }
}

.layerslider-wrapper{
  display: none;
}

@include media('large'){
  .flex-large{
    display: flex;
  }
  div.mm-page{
    z-index: initial;
  }
}

.cd-top{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #333;
  color: white;
  border: 0;
  border: 0;
  outline: none;
  box-shadow: none;
  position: fixed;
  right: 25px;
  bottom: 25px;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: 420ms ease;

  &.active{
    opacity: 1;
    visibility: visible;
  }
  span{
    display: none;
  }
}

@include media('small'){
  .flex-small{
    display: flex;
  }
  .zoomContainer{
    display: none;
  }
  .cd-top{
    position: relative;
    visibility: visible;
    opacity: 1;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 5px;
    width: auto;
    height: auto;
    padding: 5px 10px;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 45px;

    .fa{
      font-size: 14px;
      margin-left: 5px;
    }
    span{
      display: block;
      font-size: 12px;
      text-transform: uppercase;
      margin-left: 5px;
    }
  }
}