/**************************************
* FOOTER
**************************************/

.footer{
  overflow: hidden;
  margin-top: 10px;
  .box{
    justify-content: space-between;
    margin-left: 8px;
    margin-right: 8px;
  }
  .top{
    background-color: $second-color;
    color: $font-color-2;
    padding: 32px 0;
    // margin-top: 20px;
    .footer-top{
      display: block;
      @include media ("mf-large"){
        display: inline-flex;
        width: 100%;
      }
      .box-newsletter{
        @include media ("mf-large"){
          width: 75%;
          display: inline-flex;
        }        
        .top-newsletter{
          margin-right: 2%;
          @include media ("mf-large"){
            width: 42%;
          }
          h3{
            strong{
            font-size: 22px;
            font-weight: bold;
            text-transform: uppercase;
            }
          }
          p{
            font-size: 11px;
            font-weight: 500;
            padding: 3% 0;
            white-space: nowrap;
            @include media ("mf-large"){
              padding: unset;
              margin-top: 2%;
              font-size: 14px;
              white-space: unset;
            }
          }
        }
        .newsletter{
          width: 100%;
          height: 40px;
          margin-top: 1%;
          padding-right: 10px;
          @include media ("mf-large"){
            width: 53%;
            float: right;
          }
          input{
            width: 100%;
            border: none;
            height: 38px;
            color: $font-color;
            font-size: 13px;
            font-weight: 300;
            padding: 10px;
            @include media ("mf-large"){
              height: 45px;
            }
          }
          button{
            margin-top: -33px;
            position: sticky;
            border: none;
            float: right;
            background-color: white;
          }
        }
      }
      .box-redes{
        margin-left: 2%;
        margin-top: 7%;
        @include media ("mf-large"){
          margin-top: unset;
        }
        h3{
          font-size: 22px;
          font-weight: bold;
          text-transform: uppercase;
        }
        .redes{
          li{
            margin-right: 15%;
            margin-top: 4%;
            list-style-type: none;
          }
        }
      }
    }
  }
  
  .bottom{
    padding: 20px 6px;
    border-bottom: 1px solid $second-color;
    .footer-bottom{
      @include media ("mf-large"){
        display: flex;
      }
      .nossas-lojas{
        margin-top: 10px;
        @include media("mf-large"){
          margin-top: unset;
        }
      }
      .left{
        padding: 3px;
        line-height: 3.07;
        @include media ("mf-large"){
          // padding: 10px;
          display: flex;
          width: 64%;
          line-height: unset;
          &:first-child{
            width: 64%;
          }
        }
        .column{
          font-size: 14px;
          font-weight: 300;
          margin-top: 23px;
          @include media("mf-large"){
            margin-top: unset;
            margin-right: 6%;
          }
          h3{
            color: $font-color;
            font-size: 14px;
            font-weight: bold;
            line-height: 1.71;
            text-transform: uppercase;
          }
          ul{
            list-style: none;
            a{
              color: $font-color;
              line-height: 1.71;
            }
          }
        }
      }
    }
    .footer-selos{
      margin-top: 10px;
      @include media ("mf-large"){
        width: 30%;
        margin-top: unset;
      }
      .linha{
        .item{
          h3{
            font-size: 14px;
            font-weight: bold;
            line-height: 1.71;
            margin-bottom: 3%;
            text-transform: uppercase;
          }
        }
      }
    }
    p{
      font-size: 12px;
      color: $font-color;
      line-height: 1.58;
    }
  }
  .copyright{
    padding: 15px 35px;
    p{
      text-align: center;
      margin-bottom: 10px;
      font-size: 10px;
      font-weight: 300;
      @include media ("mf-large"){
        width: 50%;
        margin-bottom: unset;
        text-align: unset;
      }      
    }
    h5{
      font-size: 10px;
      text-align: center;
      font-weight: 300;
      @include media ("mf-large"){
        width: 50%;
        text-align: unset;
      }
    }
    .linha{
      align-items: center;
      padding-left: 4%;
      @include media ("mf-large"){
        float: right;
        width: 50%;
        align-items: center;
        margin-top: -5%;
        display: flex;
      }
      .row{
        text-align: -webkit-center;
      }
      .item{
        display: inline-block;
        padding: 0 6%;
        @include media("mf-large"){
          padding: 0 19px 0 0;
        }        
        .list{
          vertical-align: middle;
          align-items: center;
        }
      }   
      .logistica{
        vertical-align: middle;
        align-items: center;
        .administrado{
          display: block;
          text-align: -webkit-center;
          @include media ("mf-large"){
            display: flex;
          }
        }
        h3{
          font-size: 10px;
          width: 100%;
          padding: 10px;
        }
      }
    }  
  }
}