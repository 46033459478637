/**************************************
* HEADER
**************************************/
.banner-destaq{
	background-color: #f2f4f8;
    min-height: 65px;
    width: 100%;
	padding: 19px 0 19px 35px;
	.list{
		display: block;
		list-style: none; 
		background-color: #f2f4f8;		
	}
	.owl-stage-outer {
		width: 80%;
		float: left;
		margin-left: -22px;
		a{
			width: 242px;
			height: 17px;
			font-size: 12px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
			text-decoration: none;
			color: $font-color;
		}
	}
	.owl-dots {
		float: right;
		height: 18px;
		width: 23%;
		text-align: right;
		// margin-top: 10px;
		
		.owl-dot {
			height: 10px;
			border: solid 1px $second-color;
			width: 10px;
			display: inline-block;
			margin-right: 5px;
			border-radius: 5px;
			&.active{
				background: $second-color;
			}
		}
	}
}
.banner-destaq{
	visibility: visible !important;
}

/** Box - carrinho **/
.cart:hover .content-cart {
  display: block;
}
.content-cart{
	display: none;
    top: 104px;
	color: $font-color;
	font-size: 13px;
    font-weight: 500;
    position: absolute;
    z-index: 99999;
    width: 180px;
    text-align: left;
    padding: 0 0 16px 0;
    right: 0;
    width: 340px;
    height: 897.1px;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.5);
    background-color: $primary-color;	
	.header-link-checkout{
		position: relative;
		margin: 0;
		padding: 0;
		list-style: none;		
		.item{
			vertical-align: middle;
			a{
				display: block;
				padding: 8px 5px 9px 20px;
				line-height: 1;
				font-size: 13px;
			}
			&.top{
				height: 47px;
			}
		}
	}
	.top{
	    height: 57px;
		border-bottom: 1px solid black;
		.btn-meu-carrinho{
			border: none;
			background-color: transparent;
			img{
				background-color: black;
				margin-left: -6px;
				margin-top: -1px;
			}
		}
		.qtd-itens{
			padding: 20px;
			float: right;
			font-weight: 300;
			clear: both;			
		}
	}
	.quantidade{
		min-height: 350px;
		.box{
			display: flex;
			padding: 10px;
			.box-product{
				width: 100px;
				height: 140px;
				margin-right: 10px;
				img{
					right: unset !important;
					width: 100px;
					height: 140px;
					object-fit: cover;
				}	
			}
			.box-compra{
				width: 50%;
				.desc-product{
					width: 205px;
					height: 48px;
					a{
						font-size: 13px;
						font-weight: 300;
						white-space: normal;						
					}
				}
				.tamanho{
					font-weight: 600;
    				padding-bottom: 3px;
				}
				.subtotal-product{
					font-weight: 600;
				}
				.block-qnt{
					display: inline-flex;
					.btn-quant{
						background-color: white;
						border: unset;
						font-weight: bold;
						margin-top: -6%;
						font-size: 30px;
						padding: 0 10px !important;						
					}
					.input-quant{
						width: 44px;
						height: 36px;
						background-color: #f2f4f8;
						border: unset;
						border-radius: unset;	
						text-align: center;					
					}
				}
			}
		}
	}
	.subtotal{
		border-bottom: 1px solid black;
		padding-left: 10px;
		.price-new{
			float: right;
    		clear: both;
		}
	}
	.frete{
      .frete{
		padding: 21px 0;
		.left{
			display: flex;
			padding: 20px 10px;	
          .title{
            text-transform: uppercase;
			margin-right: 11px;
			margin-top: 6px;
          }
          .calculo{
            display: flex;
			width: 141px;
			height: 40px;
            input{
			  	width: 85%;
				border: 1px solid black;
				border-right: none;			  
            }
            button{
				width: 35px;
				border: 0;
				background-color: $primary-color;
				border: 1px solid black;
				border-left: none;
            }
          }
        }
        .right{
		  background-color: #f2f4f8;
		  padding: 0 11px;		  
          .list{
            color: $font-color;
            line-height: 1.5;
            p{
                padding: 10px 0;
				border-top: 1px solid white;
            }
          }
        }
      }		
	}
	.total{
		height: 81px;
		background-color: $second-color;
		color: $font-color-2;
		padding: 10px;
		.price-new{
			float: right;
    		clear: both;
		}
		.parcelamento{
			font-weight: 300;
			padding-top: 7px;			
		}
	}
	.links{
		padding: 10px;
		height: 62px;
		background-color: $primary-color;
		display: flex;
		.continuar-comprando{
			font-weight: 600;
			text-decoration: underline;
			width: 100px !important;
		}
		.fechar-pedido{
			width: 163px;
			height: 44px;
			background-color: $second-color;
			color: $font-color-2;
			border: none;
		}
	}
}
.content-cart:before {
  content: "";
  position: absolute;
  top: -101%;
  right: 11%;
  margin: auto;
  bottom: 0px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #fff;
  transform: rotate(179deg);
  @include media('xlg'){
	  right: 57%;
  }
}
/** Box - carrinho **/
html.mm-blocking body{
	margin-top: 0 !important;
	.header{
		top: 31px !important;
	}
}
.mm-panels>.mm-panel>.mm-listview{
  margin-bottom: 0;
}
.mm-panels>.mm-panel.mm-hasnavbar{
  background-color: black;
}
.mm-panels>.mm-panel:after{
  height: 0;
}
.mm-listview>li a{
	text-transform: uppercase;
}
.main-menu li > a i{
	color: white;
	font-size: 23px;
	position: relative;
	top: 1px;
	margin-right: 5px;
}
.main-menu li > a i.fa-mobile {
	font-size: 27px;
	position: relative;
	top: 4px;
}
.main-menu .container .components  a{
	color: #5e5e5e;
	width: 195px;
	text-align: left;
	font-size: 11px;
}
.main-menu .container > ul > li.computer > a:before{
	background-position: 126px 63px;
}
.main-menu .container > ul > li.components > a:before{
	background-position: 126px 28px;
}
.main-menu .container > ul > li.periferic > a:before{
	background-position: 83px 63px;
}
.main-menu .container > ul > li.acessorios > a:before{
	background-position: 85px 100px;
}
.main-menu .container > ul > li.gaming > a:before{
	background-position: 37px 63px;
}
.main-menu .container > ul > li.monitores > a:before{
	background-position: 38px 28px;
}
.menu-inner-mobile{
	display: none;
}
.main-menu li .menu-inner{
	width: 850px;
	background: #ffffff;
	min-height: 50px;
	position: absolute;
	padding: 20px 15px;
	border: 1px solid white;
	z-index: 5;
	display: none;
	z-index:9999;
	margin-left: -7px;
}
.main-menu .computer .menu-inner{
	width: 18%;
	float: left;
}
.itens-list{
	width: 430px !important;
	display: inline;
	float: left;
}
ul.list.list-unstyled.components.ul-1, ul.list.list-unstyled.components.ul-2 {
	float: left;
}
.main-menu li .menu-inner .itens-list li{
	width: 100%;
	height: 20px;
	border-radius: 4px;
	margin-bottom: -2px;
	cursor: pointer;
	line-height: 30px;
	vertical-align: middle;
	padding-left: 15px;
	color: #757575;
	position: relative;
}
.main-menu li .menu-inner .itens-list li.escopo a:hover, .main-menu li .menu-inner .itens-list li.escopo a {
	color: $second-color !important;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: bold;
}
.main-menu li .menu-inner .itens-list li.active, .main-menu li .menu-inner .itens-list li a:hover{
	text-decoration: underline;
	color: #525252 !important;
	font-size: 13px;
	font-weight: bold;
}
.main-menu li .menu-inner .itens-list li span{
	font-size: 16px;
	font-weight: 300;
}
.main-menu li .menu-inner .itens-list li strong{
	font-weight: 500 !important;
}
.main-menu li .menu-inner .itens-list li i{
	position: relative;
	top: 7px;
	left: -15px;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 14px;
	color: #8FCEAD;
	margin-right: -12px;
}

/** flexcol **/
.flexcol-container {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 100%!important;
}

.flexcol-item {
	width: calc((100% / 4) - 1rem);
	display: block;
	padding-bottom: 7px;
}

.cols-2 .flexcol-item { width: calc((100% / 2) - 1rem); }
.cols-3 .flexcol-item { width: calc((100% / 3) - 1rem); }
.cols-4 .flexcol-item { width: calc((100% / 4) - 1rem); }
.cols-5 .flexcol-item { width: calc((100% / 5) - 1rem); }

.main-menu li .menu-inner .itens-list li.escopo a,
.main-menu li .menu-inner a{
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1; /* number of lines to show */
	line-height: 20px;        /* fallback */
	max-height: 20px;       /* fallback */
}
.main-menu li .menu-inner{
	visibility: hidden;
	opacity:0;
	display: block;
}
.main-menu li.escopo a{
	white-space:nowrap;
}
.main-menu li .menu-inner.active,
.main-menu li.active .menu-inner{
	visibility: visible;
	opacity:1;
}
ul.list.list-unstyled.components.ul-1,
ul.list.list-unstyled.components.ul-2{
	display: block !important;
	float: none !important;
}
.mm-panel.mm-hasnavbar .mm-navbar {
	background-color: $second-color;
	a{
		color: $font-color-2;
		text-transform: uppercase;
		font-size: 13px;
		font-weight: bold;		
	}
}
.mobile-menu .top-box {
	display: none;
}
.mobile-menu .bottom-box {
    padding: 15px 20px;
	background-color: $primary-color;

	.mm-navbar {
		background-color: $second-color;
		a {
			color: $font-color-2;
			text-transform: uppercase;
			font-size: 13px;
			font-weight: 500;
			line-height: 1.08;
		}
	}	
	.links-menu-mob{
		display: grid;
		.icon-category{
			width: 23px;
			margin-right: 13px;
		}
		a{
			padding-bottom: 20px;
		}
	}
	.icons-menu-mob{
		margin-left: -30px;

		img{
			padding-left: 11.5%;
		}
	}
}
.mobile-menu .mm-listview{
	background-color: $second-color;
	font-size: 13px;
	font-weight: 600;

	a{
		color: $font-color;
	}
	li span {
		color: $font-color-2;
		text-transform: uppercase;
		width: 35px;

		.icon-category{
			width: 20px;
			margin-right: 10px;
		}
	}
}
.mm-menu .mm-listview>li .mm-next:after {
    border-color: $font-color-2;
}

/* Header Desktop */
.header{
	position: fixed;
	z-index: 100;
	//z-index: z('header');
	width: 100%;
	top: 0;

	@include media('small'){
		position: inherit;
		display: block;
	}
	.content{
		background-color: transparent;
		position: relative;
		z-index: 2;
		a{
			color: $font-color;
			white-space: nowrap;
			font-weight: bold;

		}
		.box-header{
			justify-content: space-between;
			.right-container{
				background-color: white;
				display: inline-block;
				width: 100%;
				.right{
					@include container-custom();
				}
				.right-top{
					width: 100%;
					background-color: $contrast-color;
					display: none;
					@include media('mf-large'){
						display: block;
					}
					.right{
						.head-float{
							width: 100%;
							display: inline-block;
							padding: 12px 2%;
						}
					}
				}
			}
			.column{
				display: flex;
				align-items: center;
				&.logo{
					width: 467px;
					padding: 15px;
					@include media('sm'){
						width: 20%;
					}
					a{
						color: $font-color;
						padding: 0 1%;
						white-space: nowrap;
					}
				}
				&.busca{
					width: 35%;
					padding: 0 1% 0 0px;
					.input-send{
						width: 100%;
						margin-left: 0;
						height: 35px;
						align-items: stretch;
						display: flex;
						border: 1px solid $second-color;
						.input-search{
							border: 0;
							width: 85%;
							background-color: transparent;
							padding: 2px 6px;
							@include placeholder(){
								font-size: 12px;
								font-weight: 600;
								color: $font-color;
							}
						}
						.submit-btn{
							margin-left: auto;
							background-color: transparent;
							width: 15%;
							border:0;
							text-align: end;
						}
					}
				}
				&.wishlist{
					// margin-left: auto;
					padding-right: 4%;
				}
				&.links{
					.link-btn{
						display: block;
						height: 80px;
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 0 28px;
						text-decoration: none;
					}
					&.cart{
						width: 80px;
						a{
							padding: 0;
							width: 100%;
							span{
								position: relative;
								display: inline-block;
								left: 22px;
								bottom: 8px;
								background-color: $tertiary-color;
								border-radius: 20px;
								width: 20px;
								text-align: center;
								font-size: 10px;
								height: 20px;
								color: $primary-color;
								padding-top: 4px;
								z-index: 1;
							}
							img {
								position: relative;
								right: 12px;
							}
						}
					}
				}
			}
			.right-head{
				width: 27%;
				display: flex;
				margin-left: auto;
			}
		}
	}	
	&.top {
		position: relative;
		.content{
			width: 100%;
			position: fixed;
			top: 0;
		}
		.main-menu{
			margin-top: 0;
			@include media('mf-large'){
				margin-top: -50px;
			}
		}
	}
	.main-menu{
		&.animate{
			-webkit-transition: top .3s ease-out;
			-moz-transition: top .3s ease-out;
			-o-transition: top .3s ease-out;
			transition: top .3s ease-out;
		}
		.dp-flx{
			display: inline-flex;
		}
	}
	@include media('xlg'){
		.content{
			.box-header .column{
				&.ajuda{
					display: flex;
				}
			}
		}
		.main-menu .main-list{
			justify-content: space-between;

			.nivel-1-item{
				width: 100%;
				// margin-left: auto;
				// margin-right: auto;

				& > a, span{

					.icon{
						display: block;
					}
				}
			}
		}
	}
}
.menu-sm{
	background: $primary-color !important;
	.fa{
		color: $second-color !important;
	}
}
.mob-menu .icon{
	display: block;
}

/** Mobile **/
.header-mob{
	height: 60px;
	background: $second-color;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 9999999;
	h1{
		width: 53%;
	}
	.toggle-menu{
		width: 60px;
		height: 60px;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		cursor: pointer;
		// background: $second-color;
		text-decoration: none;
		i {
			color: $primary-color;
			font-size: 18px;
		}
	}
	.column {
		&.links {
			&.cart,
			&.wishlist {
				width: 15%;
				height: 60px;
				text-align: center;
			}
			&.cart {
				span{
					position: relative;
					display: inline-block;
					top: 7px;
					left: 22px;
					bottom: 8px;
					background-color: $tertiary-color;
					border-radius: 20px;
					width: 20px;
					text-align: center;
					font-size: 10px;
					height: 20px;
					color: #ffffff;
					padding-top: 4px;
					z-index: 1;
					float: left;
					clear: both;
				}
				img{
					right: 15px;
				}
			}
		}
		img {
			position: relative;
			top: 18px;
		}
	}
	.logo{
		margin-right: auto;
		margin-left: 2%;

		img{
			max-width: 60px;
		}
	}
}
.column {
	&.busca{
		&.mobile{
			margin-top: 10px;
			.input-send {
				height: 50px;
				// border-bottom: solid 1px $second-color;
				padding: 15px 11px;
				.input-search{
					border: none;
					@include placeholder {
						color: $font-color;
						font-size: 13px;
						font-weight: 500;
					}						
				}
				.submit-btn{
					background: $primary-color;
					border: 0;
					float: right;
					clear: both;
				}
			}
		}
	}
}	
.main-menu{
	.mob-menu{
		position: fixed;
		top: 110px;
		z-index: 10;
		background: $second-color;
		width: 100%;
		left: 0;
		height: 100vh;
		overflow: auto;
		border-top: 1px solid $second-color;
		display: none;

		.main-list{
			display: block !important;
			border: none;
			height: auto;
			overflow: overlay;
			height: calc(100vh - 110px);
			width: 100%;

			.hello{
				background: white;
				padding: 1px 0;

				.right{
					margin: 20px 0 0 0;

					a{
						color: $second-color;
						display: block;
						padding: 0 0 20px 20px;
						text-decoration: underline;
						i{
							padding-right: 5px;
						}
					}
				}
			}
			.nivel-1-item{
				&:hover{
					& > .submenu{
						display: none;
						width: 90%;
					}
				}

				a{
					padding-right: 0;
					box-shadow: none !important;
					min-height: 50px;
				}
			}
			.submenu {
				li{
					&:hover{
						.nivel-2{
							width: 90%;
							display: none;
							box-shadow: none;
						}
					}
				}
			}
			li{
				min-height: 50px;

				a{
					span{
						display: block;
						width: 100%;
						text-align: left;

						&.toggle-children{
							max-width: 85px;

							i{
								float: right;
								text-align: center;
								height: 50px;
								width: 100%;
								font-size: 20px;
								position: relative;
								padding-top: 12px;
							}
						}
					}
				}
			}
			.submenu,
			.nivel-2{
				position: relative;
				z-index: 11;
				background: #fff;
				width: 90%;
				top: 111px;
				left: 0;
				height: 100vh;
				overflow: auto;
				margin: 0;

				.toggle-children{
					min-width: 85px;
				}
			}
		}
		.icon{
			width: 40px;
			height: 20px;
			margin-right: 15px;
			background-size: contain;
			img{
				width: 20px;
			}
		}
	}
}
.mm-menu{
	background: $primary-color;
	.hello{
		display: flex;
		align-items: center;
		font-size: 13px;
		margin-right: 30px;
		padding: 15px 20px;
		background: $primary-color;
		width: 100%;

		span{
			line-height: 12px;
			margin-left: 6px;
			margin-top: 2px;

			a{
				color: black;
				text-decoration: none;

				&:after{
					background: #c6c6c6;
					width: 100%;
				}
			}
		}
	}
	.mm-listview>li{
		&.todos{
			display: none;
		}
		a{
			display: flex;
			align-items: center;
			color: $font-color-2;
			font-size: 13px;
		}
		.toggle-children{
			display: none;
		}
	}
}
.mm-opening{
	.header-mob .toggle-menu{
		justify-content: center;

		span{
			&:nth-child(1){
				transform: rotate(45deg);
			}
			&:nth-child(2){
				display: none;
			}
			&:nth-child(3){
				transform: rotate(-45deg);
			}
		}
	}
}
.menu-on{
	.column{
		&.busca{
			&.mobile{
				position: fixed;
				top: 50px;
				border-bottom: 1px solid #e9e9e9;
				border-radius: 0;
				z-index: 10;
				width: 100%;
				.input-search,
				button{
					border: none !important;
					border-radius: 0;
				}
			}
		}
	}
}

/** Desktop **/
@include media ('mf-large'){
	.hidden-large{
		display: none !important;
	}
	.menu-sm{
		display: none;
	}
}

/** Tablet **/
@include media ('sm'){
	.header{
		position: fixed;
	}
	.header .content .box-header {
		.logo img{
			max-width: 104px;
		}
		.right-head {
			width: 20%;

			.head-float {
				margin-top: 9px;
				margin-left: -272%;
				transform: translateX(9%);
			}
			.head-bottom {
				margin-top: -10px;

				img{
					width: 35px;
					height: 35px;
				}

				.minha-conta {
					margin-left: 28%;
				}
			}	
		}
	}	
	.header .content .box-header .column.links.cart a span{
		margin-top: -19px;
		width: 25px;
		height: 25px;
		left: 3px;
		font-size: 14px;
		padding: 3px;
	}
	.header .content .box-header .right-head 
	.header .content .box-header .column.ajuda .help,
	.header .content .box-header .column.login{
		display: none;
	}	
	.header .content .box-header .column.busca {
		width: 90%;
		margin-left: 3%;
		.input-send{
			height: 33px;
		}
	}	
	.wrapper{
		margin-top: -53px;
	}
	.header .content .box-header .right-top a {
		margin: 0 6px 0 10px;
	}	
	.toggle-menu{
		width: 84px;
		height: 104px;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		cursor: pointer;
		background: $second-color;
		text-decoration: none;
		i {
			color: $primary-color;
			font-size: 33px;
			margin-left: 20px;
			margin-right: 20px;
		}
	}
}