/** Bloco individual de produtos **/
.opcoes-indisponivel {
  background: #FF0000;
  border-radius: 50%;
  color: #fff;
  height: 12px;
  position: absolute;
  right: 0px;
  text-align: center;
  top: -3px;
  width: 12px;
  z-index: 4;
  font-size: 9px;
  .select-size{
    color: #5d5d5d !important;
    // &:hover,
    // &.active{
    //   color: $font-color !important;
    // }
  }  
}

.btn-product-detail,
.btn-product-detail2{ //Botão de compra e avise-me
  line-height: 1.5;
  text-align: center;
  padding: 13px;
  background: $primary-color;
  color: $font-color;
  border: 1px solid #ededed;
  margin: auto;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  width: 95%;
  height: 44px;
  margin-left: 2%;
  display: none;
  position: absolute;
  // margin-top: -78px;
  margin-top: -123px;
  text-decoration: none;
  margin-bottom: 0;
  z-index: 2;
  transition: .3s ease;
  @include media ('mf-x-small'){
    display: block;
    opacity: 0;
  }
  &:hover{
    background: $second-color;
    color: $font-color-2;
    text-decoration: none;
    border: 0;
    opacity: 1;
    a{
      color: $font-color;
      text-decoration: none;
    }
  }
}
.btn-product-detail2{
  font-size: 10px;
}
.opcoes-card{ //Opções do produto no card
  height: 20px;
  // margin-top: -21px;
  .option{
    text-align: center;
    display: inline-block;
    background-color: $second-color;
    color: $font-color-2;
    width: 100%;
    margin-top: -24px;
    .radio{
      margin-bottom: unset;
    }
    .opcoes-card-text{
      font-weight: 500;
      margin-top: 0;
      display: inline-block;
      height: auto;
      .select-size{
        font-size: 12px;
        color: $font-color-2;
        height: 23px;
        position: relative;
        text-align: center;
        font-weight: 500;
        padding: 2.5px 3px;
        @include media('mf-large'){
          padding: 2.5px 0.9px;
        }
        // &:hover, 
        // &.active{
        //   color: $font-color;
        //   background-color: $primary-color;
        // }
      }
      &.opcao-indisponivel{
        .select-size{
          color: #5d5d5d !important;
        }
      }      
    }    
  }
}
.product-item-block{ //Produto
  .inner{
    transition: 10ms ease;
    background: $primary-color;
    .middle{
      margin: 0 0 0 0px;
      position: relative;
      border: 1px solid #ededed;
      text-align: center;
      &:hover{
        .btn-product-detail,
        .btn-product-detail2{
          opacity: 1;
        }      
      }
      .promotags{
        display: block;
        position: absolute;
        width: 100% !important;
        height: 100% !important;
        top: 0px;
        left: 0;
        transition: 0.5s;
      }
      .promotags-bottom{
        .promotags{
          background-position-y: 63% !important;
          @include media("mf-x-small"){
            background-position-y: 80% !important;
          }
        }
      }
      img{
        max-width: 100%;
      }
    }
    .bottom{
      display: flex;
      flex-direction: column;
      padding: 6px 5px 10px 5px;
      position: relative;
      min-height: 180px;
      @include media("mf-x-small"){
        min-height: 160px;
        padding: 13px 5px 10px 5px;
      }
      h3{
        font-size: 14px;
        font-weight: 300;
        text-align: center;
        text-transform: uppercase;
        padding-bottom: 6px;
        height: 56px;
        width: 100%;
        @include media('custom-small'){
          height: 52px;
        }
        a{
          color: $font-color;
          @include line-limit(3, 1.2);
        }
      }
      .inner-box{
        text-align: center;
      }
      .parcelas{
        color: $second-color;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        @include media('mf-x-small'){
          font-size: 13px;
          display: flow-root;
        }        
      }
      .de{
        font-size: 13px;
        font-weight: 500;
				color: $font-color-3;
        @include media('mf-x-small'){
          display: inline-block;
        }
        .value{
          text-decoration: line-through;
          font-weight: 300;
        }
      }
      .por{
        font-size: 13px;
        color: $font-color-3;
        font-weight: 500;
        text-align: center;
        padding-bottom: 7px;
        height: 27px;
        padding-top: 11px;
        @include media("mf-large"){
          height: unset;
          padding-top: unset;
        }
        @include media('mf-x-small'){
          display: inline-block;
          height: unset;
        }
      }
      .new-price{
        height: 9px;
        padding-top: unset;
        @include media('custom-small'){
          height: unset;
        }
      }
			.avista{
				font-size: 14px;
				color: $second-color;
				display: block;
			}
    }
  }
  .relacionados{
    display: block;
    height: 50px;
    background-color: rgba(255,255,255, 0.8);
    overflow: hidden;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
    // transition: 0.5s;
    opacity: 0;

    .box {
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
      height: 100%;

      .slick-arrow {
        position: relative;
        cursor: pointer;
        color: $second-color;
        padding: 5px 5px;
        height: 20px;
        font-size: 10px;

        &.slick-next{
          left: -1px;

          border-radius: 3px 0 0 3px;
        }
        &.slick-prev{
          right: 20px;
          margin-left: 20px;
          border-radius: 0 3px 3px 0;
        }
      }
      .relacionado{
        height: auto;
        overflow: hidden;
        display: inline-block;
        padding: 0 2px 0 5px;
        min-width: 40px;

        img{
          width: auto;
        }
      }
    }
  }
  &:hover{
    .inner .middle .promotags-bottom{
      .promotags{
        background-position-y: 54% !important;
      }
    }    
    @include media("mf-large"){
      .relacionados{
        bottom: 116.5%;
        opacity: 1;        
      }
      .btn-product-detail,
      .btn-product-detail2{
        margin-top: -100px;
      }    
    }
  }  
}
/** Fim Bloco individual de produtos **/

/** Carrossel de produtos **/
.product-carousel{
  position: relative;
  // padding: 5% 0;
  .container{
    padding-left: unset;
    padding-right: unset;
  }

  .box{
    .title{
      align-items: center;
      display: block;
      .title-icon{
        display: block;
        height: 30px;
        background-repeat: no-repeat;
        width: 100%;
        background-position: center center;
        background-size: contain;       
        @include media('mf-x-small'){
          height: 40px;        
        }
      }
      span{
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 300;
        color: $font-color;
        text-align: center;
        width: 100%;
        display: block;
        padding: 4px 0;
        strong{
          font-weight: 600;
          font-size: 30px;
          line-height: 1;
        }
      }
    } 
    .owl-dots{
      display: flex;
      position: absolute;
      list-style: none;
      top: 0;
      width: 100%;
      justify-content: center;
      li{
        padding: 0.5%;
        button{
          display: block;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          border: 1px solid $second-color;
          color: transparent;
          font-size: 0;
          background-color: transparent;
        }
        &.slick-active{
          button{
            background: $second-color;
          }
        }
      }
    }    
    .control{
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;
      padding: 10px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      z-index: 99;
      &.prev{
        background-color: $second-color;
        left: 0;
        display: none !important;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
        @include media ('mf-large'){
          display: flex !important;
        }
        @include media("mf-x-large"){
          left: -3%;
        }
        .fa{
          right: 2px;
        }
      }
      &.next{
        background-color: $second-color;
        right: 0;
        display: none !important;
        box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.4);
        @include media ('mf-large'){
          display: flex !important;
        }
        @include media("mf-x-large"){
          right: -3%;
        }        
        .fa{
          left: 2px;
        }
      }
      .fa{
        color: $primary-color;
        position: relative;
        font-size: 15px;
      }
    }
    .list{
      display: block;
      // max-height: 594px;
      overflow: hidden;
      padding-top: 20px;
      .item{
        padding: 7% 4% !important;
        // max-width: 248px;
        margin-left: auto;
        margin-right: auto;
        @include media ('lim-sm'){
          max-width: 248px;
        }
      }
    }
  }  
  &.mais-visualizados{
    .title-icon{
      background-image: url('../../images/icon-sacola.svg');
    }
  }
  &.promocoes{
    .title-icon{
      background-image: url('../../images/icon-sacola.svg');
    }
  }
  &.buscados{
    .title-icon{
      background-image: url('../../images/icon-sacola.svg');
    }
  }
  &.most-sold{
    .title-icon{
      background-image: url('../../images/icon-sacola.svg');
    }
  }
  &.sugestoes{
    margin-top: 10px;
    .title-icon{
      background-image: url('../../images/icone-sugestoes-2.png');
      height: 44px !important;
      margin-bottom: 19px;
    }
    .opcoes-card{
      display: none;
    }
    .list{
      & > div:last-child{
        display: none;
      }
      .product-item-block .inner .middle{
        border: none;
      }
    }
    @include media ('mf-large'){
      .box{
        .title{
          span strong{
            font-size: 41px;  
          }
          .title-icon{
            margin-bottom: 9px;
          }
        }
        .list{
          padding-top: 0;
          & > div:last-child{
            display: block;
          }
          .col-lg-3{
            width: 20%;
            padding-left: 5px;
            padding-right: 5px;
            .item{
              padding: 7% 0 !important;
            }
          }
        }
      }
    }
  }
}

/** Breadcrumb **/
.navegacao{
  margin: 20px 0;
  li{
    a{
      font-size: 12px;
      color: $font-color;
      font-weight: 300;
    }
    &.last{
      a{
        font-weight: 600;
      }
      .divisor{
        display: none;
      }
    }
    .divisor{
      font-size: 10px;
      color: $second-color;
      margin-left: 10px;
    }
  }
}
.shadow-main{
  display: block;
  position: absolute;
  top: 0;
  background: black;
  opacity: 0.8;
  z-index: 5;
}

/** Toll tip **/
.tooltip-text {
  position: relative;
  display: inline-block;
  font-size: 13px;
  font-weight: 300;  
}
.tooltip-text .tooltip-span {
  visibility: hidden;
  font-size: 12px;
  font-weight: 300;  
  color: $font-color;
  text-align: center;
  padding: 8px;
  position: absolute;
  width: 211px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  background-color: $primary-color;
  z-index: 999999;
  white-space: pre-wrap;
  margin-top: 22px;
  left:0;
}
.tooltip-span:before {
  content: "";
  position: absolute;
  top: -101%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #fff;
  transform: rotate(179deg);
}
.tooltip-text:hover .tooltip-span {
  visibility: visible;
}

/** Boss Mega Menu Desktop **/
.menu{
  @include container-custom();
}
.main-menu{
  height: 90px;
  background-color: white;
  border-top: 1px solid $second-color;
  border-bottom: 1px solid $second-color;
  width: 100%;
  position: relative;
  transition: .5s ease;
  // z-index: z('main-menu');
  z-index: 10000;
  .main-list{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    list-style: none;
    float: left;
    height: 90px;
    width: 100%;
    &:before{
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: 0px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $primary-color;
      display: none;
      transform: rotate(179deg);
    }
    .logo-menu{
      img{
        width: 135px;
        padding: 20px;
        filter: brightness();
        -webkit-filter: invert(100%);
      }
    }
    .nivel-1-item{
      position: relative;
      // width: 100%;
      display: inline-flex;
      align-items: center;
      & > span,
      & > a{
        white-space: nowrap;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-size: 8.5px;
        font-weight: 600;
        color: $font-color;
        text-align: center;
        padding-left: 6%;
        padding-right: 6%;
        position: relative;
        cursor: pointer;
        text-transform: uppercase;
        @include media ('mf-x-large'){
          font-size: 10.5px;
        }
        img{
          display: block;
          /*max-width: 11.5px;*/
          margin-left: auto;
          margin-right: auto;
          padding-bottom: 8px;
          filter: brightness();
          -webkit-filter: invert(100%);
        }
      }
      &:hover{
        .submenu{
          display: block;
        }
      }
      &.direita{
        .submenu{
          left: initial;
          right: 0;

          li{
            flex-direction: row-reverse;
            &.no-submenu{
              & > a{
                padding-left: 60px;
              }
            }
            .toggle-children{
              border-left: 0;

              &.hover{
                min-width: 45px;
                left: -1px;
                right: 0;
                padding-left: 2px;
              }
              .fa{
                -moz-transform: scaleX(-1);
                -o-transform: scaleX(-1);
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
                filter: FlipH;
                -ms-filter: "FlipH";
              }
            }
          }
          ul{
            left: initial;
            right: 100%;
            border-left: 0;
            border-right: 1px solid #e2e2e2;
          }
        }
      }
    }
    .submenu{
      position: absolute;
      top: 100%;
      z-index: 999;
      background: $primary-color;
      list-style: none;
      text-align: left;
      display: none;
      min-width: 206px;     
      transition: opacity .5s;
      &.left-menu, &.left-menu .dropdown-menu{
        left: 0;
      }
      &.right-menu, &.right-menu .dropdown-menu{
        right: 0;
      }
      &.active{
        display: block;
      }
      .dropdown-menu{
        height: 462px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
        background-color: $primary-color;
        position: fixed;
        display: flex;
        margin: auto 2.5%;
        @include media('xxlg'){
          margin: auto 10%;
        }
        .dropdown-menu-left{
          margin: 30px 0 35px 50px;
          .nivel-2-item{
            margin-left: 25px;
            a{
              font-size: 15px;
              padding: 2px 0;
            }
          }
          .menu-overflow{
            overflow: scroll;
            height: 368px;
            &::-webkit-scrollbar-track {
              background-color: white;
            }
            &::-webkit-scrollbar {
              width: 6px;
              background-color: white;
            }
            &::-webkit-scrollbar-thumb {
              background: white;
              border-radius: 5px;
              border: 1px solid black;
            }
          }
        }
        .dropdown-menu-right{
          padding: 50px 20px 50px 10px;
          display: flex;
          .title-dropdown-menu-right{
            padding: 0 18px;
            img{
              height: 361px;
              object-fit: cover;
            }
          }
          .box{
            max-width: 560px;
            .dropdown-menu-image{
              padding: 5px 10px 10px 0px;
              display: inline-block;
              img{
                width: 190px;
                height: 107px;
                object-fit: cover;
                @include media('mf-x-large'){
                  width: 167px;
                  height: 166px;
                }
                &:hover{
                  filter: brightness(50%);
                }
              }
              span{
                margin-top: -28px;
                position: absolute;
                text-transform: uppercase;
                color: $font-color-2;
                font-weight: 600;
                font-size: 14px;
                padding: 0 12px;
              }
            }
          }
        }
      }
      // ul{
      //   list-style: none;
      //   display: none;
      //   position: absolute;
      //   top: 0;
      //   left: 100%;
      //   z-index: 999;
      //   background: $primary-color;
      //   min-width: 250px;
      //   border-left: 1px solid #e2e2e2;
      //   min-height: 100%;         
      //   // &.nivel-3{
      //   //   background: $primary-color;

      //   //   li{
      //   //     & > a:hover{
      //   //       background: $primary-color;
      //   //     }
      //   //   }
      //   // }
      //   &.nivel-2{
      //     li .toggle-children.hover{
      //       background: $primary-color;
      //     }
      //   }
      //   &:hover{
      //     display: block;
      //   }
      // }
      li{
        display: flex;
        align-items: stretch;
        position: static;
        &:hover{
          &.nivel-item{
            ul {
              display: block;
            }
          }
        }
        .nivel-2{
          background: #f4f4f4;
          // box-shadow: inset 5px 0 10px 0 rgba(0, 0, 0, 0.1);
          li{
            border: none;
            border-bottom: 1px solid $primary-color;
          }
          a{
            color: $primary-color;
          }
        }
        .level-2-item{
          background: #f4f4f4;
          span{
            i{
              color: $second-color;
            }
          }
        }
        a{
          display: block;
          padding: 5px 0;
          font-size: 16px;
          font-weight: 300;
          color: $font-color;
          text-decoration: none;
          text-align: left;
          text-transform: uppercase;
          &:hover{
            font-weight: 600;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .icons{
    display: none;
  }
}
.title-submenu, .title-submenu a{
  padding: 0 3px 10px 0;
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  color: #000;
}
.nivel-1-item.has-submenu:hover:before {
  content: "";
  position: absolute;
  left: 29%;
  right: auto;
  margin: auto;
  bottom: -1px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #000;
  transform: rotate(179deg);
}

.overlay-instagram {
  transition: .5s ease;
  position: absolute;
  top: 28%;
  margin-left: 5%;
  text-align: center;
  img{
    width: 20px !important;
    height: 20px !important;
    opacity: 0;
  }
}
.dropdown-menu-image:hover .overlay-instagram {
  opacity: 1;
  img{
    opacity: 1;
  }
}

/** Menu retractil **/
.menu-retractable.show-menu {
  height: 75px;
  top: 0; 
  position: fixed; 
  // z-index: z('menu-retractable');
  z-index:1000;
  background-color: $second-color !important;
  .main-list{
    height: 75px;
    .nivel-1-item > a{
      color: $font-color-2 !important;
    }
    .logo-menu{
      img{
        width: 106px;
        padding: 9px;
        filter: none;
      }
    }
  }
  .btn-busca{
    background-color: $second-color;
    border: 0;
    margin-left: -12%;
    margin-top: 22px;
  }
  .box-input{
    width: 440px;
    height: 68px;
    background-color: #ffffff;
    margin-top: 89%;
    margin-left: -408px;    
    &:before{
      content: "";
      position: absolute;
      top: 66px;
      margin: auto;
      bottom: 0px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 9px solid $primary-color;
      display: block;
      transform: rotate(179deg);
      margin-left: 417px;
    }
    .input-send{
      height: 40px;
      background-color: $primary-color;
      padding-top: 12px;
      padding-left: 17px;
      padding-right: 17px;
      display: flex;
      .input-search{
        height: 40px;
        border: 1px solid $second-color;
        border-right: none;          
        width: 600px;
        font-size: 13px;
        font-weight: 300;
        color: $font-color;
        padding: 0 15px;
        @include placeholder(){
          font-size: 12px;
          font-weight: 600;
          color: $font-color;
        }      
      }
      .submit-btn{
        border: 0;
        background-color: $primary-color;        
        padding-top: 1px;
        border: 1px solid $second-color;
        border-left: none;
        height: 40px;
      }
    }  
  } 
  .busca-retractable-menu{
    margin-left: 1%;
  }
  .cart-retractable-menu{
    margin-left: 1%;
    margin-right: 1%;
    .badge-custom{
      position: relative;
      display: inline-block;
      left: 2px;
      bottom: -9px;
      background-color: $tertiary-color;
      border-radius: 20px;
      width: 20px;
      text-align: center;
      font-size: 10px;
      height: 20px;
      color: #fff;
      padding-top: 4px;
      z-index: 1;
    }
    img{
      position: relative;
      right: 9px;
      top: 0px;     
      width: 25px;
      height: 25px;          
    }
  }
}

/** Controles menu **/
@include media("mf-large"){
  .hidden-busca{
    display:none;
  }
  .show-icons{
    display: block !important;
  }
  .hidden-icons{
    display:none !important;
  }
  .hidden-menu {
    display:none !important;
  }
}

/** Compre por look Home **/
.compre-look-home{
  background-color: $contrast-color;
  padding: 40px 0;
  overflow: hidden;
  .title{
    font-size: 30px;
    strong{
      font-size: 40px;
    }
    img {
      margin-bottom: 10px;
    }
    .icon-look{
      width: 35px !important;
      height: 40px !important;
      margin-left: 50%;
      transform: translateX(-50%);      
    }
  }
  .look-row{
    @include media("mf-x-small"){
      min-height: 400px;
      
    }
  }
  .look .look-list .look-row .look-book-wrapper .look-detail{
    @include media("small"){
      max-width: 215px;
      float: left;
      clear: both;
      left: 50%;
      position: relative;
      transform: translateX(-50%);
      padding: 0 4px;   
    } 
  }
  .look .look-list .look-row .look-book-wrapper .look-detail .look-img-link{
    @include media("mf-large"){
      max-width: 215px;
      float: left;
      clear: both;
      left: 50%;
      position: relative;
      transform: translateX(-50%);
    }
    img{
      /*opacity: 0.5;*/
      @include media("mf-large"){
        width: 188px !important;
        height: 400px !important;
        object-fit: cover !important;
      }    
    } 
  }
  .look .look-list .look-row .look-book-wrapper .look-detail{
    .look-item-wrapper{
      @include media("small"){
        width: 96.5%;
      }
    }
    @include media("mf-large"){
      &:hover{
        .look-img-link{
          max-width: unset !important;
          img{
            width: 100% !important;
            object-fit: contain;
            opacity: 1;
            z-index: 999; 
          }  
        } 

      } 
    }
  }
  .look .look-list .look-row .look-book-wrapper .look-detail .look-item-wrapper{
    @include media("mf-large"){
      width: 85%;
      bottom: 0;
    }
  }
}

/* Módulo look */
.look{
  @include media("mf-large"){
    .slick-list{
      overflow: visible;
    }
  }
  .look-detail{
    @include media("small"){
      position: relative;
    }
    @include media("mf-large"){
      &:hover{
        img{
          opacity: 1!important;
        }
        .look-item-wrapper{
          opacity: 1;
        }
      }      
    }
    .look-img-link{
      img{
        // width: 280px;
        // height: 500px;
        // object-fit: contain;
        /*opacity: 0.5;*/
      }
    }
    .look-item-wrapper{
      position: absolute;
      opacity: 1;
      width: 85%;
      bottom: 0;
      @include media("mf-large"){
        width: 85%;
        height: auto;
        background-color: #ffffff;
        opacity: 0;
        bottom: 0;
      }
      .look-item{
        display: flex;
        padding: 10px;
        @include media("small"){
          display: none;
        }
        .look-item-img{
          img{
            width: 50px;
            height: 70px;
            object-fit: contain;
          }
        }
        .look-description{
          padding: 0 10px;
          .look-list{
            a{
              color: $font-color;
              font-size: 12px;
            }
          }
          .look-text{
            font-weight: 300;
          }
          .look-price{
            font-weight: 600;
            margin-top: 8px;
          }
        }
      }
      .look-item-bottom{
        @include media("mf-large"){
          padding: 10px;
        }
      }
      .btn-compre-look{
        padding: 10px;
        button{
          width: 100%;
          background-color: $second-color;
          color: $font-color-2;
          border-radius: unset;
          padding: 10px;
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
        }
      }
    }
  }
}
/* Módulo look */

.buttom-look{
  width: 240px;
  height: 44px;
  color: black;
  border: 1px solid black;
  padding-top: 12px;
  border-radius: unset;
  text-transform: uppercase;
}

.btn-compre-look{
  width: 100%;
  height: 36px;
  color: $font-color-2;
  background-color: $second-color;
  padding-top: 12px;
  border-radius: unset;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  &:hover{
    color: $font-color;
    background-color: $primary-color;
    border: 1px solid $second-color;
  }
  @include media("xxs"){
    font-size: 10.2px;
  }
}

.no-padding-xs{
  @include media("xxs"){
    padding-left: unset !important;
    padding-right: unset !important;
  }
}