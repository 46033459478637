/**************************************
* DEFAULT VARIABLES
**************************************/
$primary-color: #ffffff;
$second-color:  #000000; //principal cor dos elementos
$tertiary-color:#7ed321; //verde contraste
$quart-color:   #e9e9e9; //linhas divisórias
$contrast-color:#f2f4f8; //azul de destaque

$font-color:    #000000; //cor dos textos
$font-color-2:  #ffffff; //cor dos textos em destaque
$font-color-3:  #9b9b9b; //cor dos textos explicativos
$shadow-color:  rgba(0, 0, 0, 0.1);