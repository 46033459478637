/**************************************
* BREADCRUMB
**************************************/
.bread{
  margin: 20px 0;

  li{
    padding: 0;

    &:not(:last-child){
      &:after{
        content: "»";
        color: #969696;
        font-size: 11px;
      }
    }
    a{
      color: #969696;
      font-size: 11px;
      text-decoration: none;
    }
    &:last-child{
      a{
        color: #313131;
        text-transform: uppercase;
      }
    }
  }
}
