/**************************************
* CSS FOR ALL PAGES
**************************************/
@import "../base/variables";
@import "../base/mixins";
@import "../base/resets";
@import "../base/fonts";
@import "../base/modais";
@import "../base/components";
@import "../partial/header";
@import "../partial/footer";
@import "../partial/breadcrumb";
@import "../partial/reviews";

.modal-aviseme .aviseme-dados-usuario input.error + label.error {
  color: #F00;
  padding-left: 0px;
  margin-top: 3px;
  font-size: 13px;
}

.modal-aviseme .aviseme-dados-usuario input.error {
  border: 1px solid red;
}
.erro-no-campo{
  color: red;
}

@include media('xxs'){
  #modal-aviseme .modal-dialog{
    position: absolute;
    margin: 0 10%;
  }
}

.new-modal-aviseme {
  .modal-body {
    .close {
      background: transparent;
      border: none;
      position: absolute;
      top: 2px;
      right: 3px;
      font-size: 20px;
      color: $second-color;
      @include media('xxs'){
        top: -7px;
        right: -10px;
      }
    }
  }

  .aviseme-informacoes-produto {
    margin-top: 14px;
    padding: 15px 10px;
    // border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    @include media('xxs'){
      margin-top: 2px;
    }

    .aviseme-nome-produto {
      font-size: 12px;
      text-align: center;
      color: $font-color;
      text-transform: uppercase;
      display: block;
      text-align: center;
      margin-bottom: 18px;
      @include media('xxs'){
        margin-bottom: 0;
      }
    }
    .stars {
      text-align: center;
      margin-bottom: 5px;

      .fa-star {
        color: $primary-color;
      }
      .fa-star-o {
        color: #bababa;
      }
    }
    .aviseme-product-image {
      border-top: 1px solid #f4f4f4;
      margin: 0 -10px;

      img {
        margin: 0 auto;
        @include media('xxs'){
          max-width: 60%;
        }
      }
    }
    .info-produto-indisponivel {
      background: $second-color;
      font-size: 13px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      height: 35px;
      text-align: center;
      margin: 0 -10px -15px -10px;
      // border-bottom-right-radius: 5px;
      // border-bottom-left-radius: 5px;
      padding-top: 8px;
      @include media('xxs'){
        height: 25px;
        padding-top: 4px;
      }

      img {
        float: left;
        position: relative;
        left: 10px;
        top: 1px;
      }
    }
  }
  .aviseme-dados-usuario {
    margin: 15px 0 0 0;
    .form-group {
        margin-bottom: 5px;
    }      

    .avise-me-chegar {    
      h3 {
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $second-color;
        text-transform: uppercase;
        margin-bottom: 10px;
        @include media('xxs'){
          font-size: 15px;
        }
      }
      p {
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.36;
        letter-spacing: normal;
        color: $font-color;
        margin-bottom: 15px;
        @include media('xxs'){
          font-size: 11px;
          margin-bottom: 2px;
        }

      }
    }
    .form-group {
      label {
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $font-color;
        @include media('xxs'){
          font-size: 10px;
        }
      }
    }
    .aviseme-btn-salvar {
      border: 0;
      border-radius: unset;
      background-color: $second-color;
      font-size: 18px;
      font-weight: 600;
      color: $primary-color;
      width: 100%;
      height: 40px;
      text-transform: uppercase;
    }
  }
}

/* Formulário trabalhe conosco */
.contact-content {
  margin-bottom: 30px;
}

.trabalhe-conosco{
  width: 100%;
  margin-top: 34px;
  @include media("mf-large"){
    width: 75%;
  }
  .contact-info p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.88;
    letter-spacing: normal;
    color: $font-color;
    margin-top: 30px;
    max-width: 500px;
  }
  .texto-trabalhe {
    margin-top: 29px;
    font-size: 16px;
    color: $font-color;
    line-height: 30px;
    font-weight: lighter;
  }
  .contact__title {
    font-size: 18px;
    font-weight: 500;
    color: $font-color;
    padding: 10px 0;
    text-transform: uppercase;
    border-bottom: 1px solid $second-color;
    margin: 30px 0;
  }
  .form-group {
    display: inline-table;
    width: 100%;
    @include media("mf-large"){
      width: 48.5%;
      &:nth-child(2n) {
        margin-left: 19px;
      }
      &:last-child{
        margin-left: unset;
      }
    }
    label {
      font-size: 13px;
      font-weight: 500;
      color: $font-color;
      span{
        color: #cccccc;
      }
    }
    textarea,
    select,
    input{
      border: 1px solid $second-color;
      border-radius: unset;
      height: 40px;
    }
  }
  .contact__buttons{
    margin-top: 30px;
    @include media("mf-x-small"){
      display: flex;
    }
    .left{
      width: 50%;
      .g-recaptcha {
        display: inline-block;
        transform: scale(0.99);
        -webkit-transform: scale(0.99);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
      }      
    }
    .right{
      width: 50%;
      margin-left: 19px;
      input[type=submit] {
        height: 44px;
        width: 100%;
        background-color: $second-color;
        border-radius: unset;
        font-size: 13px;
        font-weight: 500;
        color: white;
        margin: 0 auto;
        display: block;
        border: 0;
      }
    }
  }
}
/* Modal loja confiável */
@include media('mf-x-small'){
	.btn_loja_conf{
		position: fixed;
		top: 50%;
		z-index: 99999999;
		background-color: $second-color;
		cursor: pointer;
		width: 30px;
		height: 167px;
		display: block !important;
		right: 0;
		box-shadow: 0 0 10px 0 rgba(0,0,0, 0.3);
		img{
			width: 30px;
			height: 167px;
		}
	}
	.modal_lojasegura{
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.7);
		z-index: 9999999;
		position: fixed;
		top:0;
	}
	img.site_seguro {
		position: absolute;
		top: 40%;
		left: 0;
		right: 0;
		margin: auto;
		width: 100%;
		padding: 0 20px;
		max-width: 1000px;
		@include media('mf-x-small'){
			padding: 0 80px;
			top: 43%;
		}
	}
	.bloco_icon {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
	}
	.title-loja-conf{
		position: absolute;
		top: 30%;
		color: $font-color-2;
		font-size: 16px;
		font-weight: 500;
		text-align: center;
		width: 100%;
		@include media('mf-x-small'){
			font-size: 30px;
		}
		img{
			width: 25px;
			margin-left: auto;
			margin-right: auto;
			@include media('mf-x-small'){
				width: 30px;
			}
		}
	}
}
/* Modal loja confiável */